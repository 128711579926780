import { UserData } from '@/api/protocol';
import { reactive } from 'vue';

class User {
  data?: UserData;

  constructor() {
    const storedData = localStorage.getItem('userInfo');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.data = parsedData;
    }
  }

  save(data: UserData) {
    this.data = data;
    localStorage.setItem('userInfo', JSON.stringify(data));
  }
}

export default reactive(new User());
