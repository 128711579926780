import dataCenter from '@/dataCenter';
import qs from 'qs';

/**同步地址栏参数到全局变量 */
export function syncQuery() {
  const query = qs.parse(window.location.href.split('?')[1]?.split('#/')?.[0]);
  Object.entries(query).forEach(([k, v]) => {
    dataCenter.webConfig.query[k] = v;
  });
}
