import { ApiCode, FRESH_TOKEN_KEY } from '@/manager/const';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import qs from 'qs';
import { tokenController } from '../manager/token';
import { FRESH_TOKEN_URL } from './protocol';
import { lang } from '@/lang';
import router from '@/router';
import message from '@/utils/message';
const service: AxiosInstance = axios.create({
  baseURL: window.server || process.env.VUE_APP_SERVER,
  // baseURL:"http://192.168.110.65:10001",
  timeout: 10 * 1000, // 请求超时时间
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
});
const WhiteUrl: string[] = ['/server/market/wallet/balance/extract/fields'];
const BlockUrl: string[] = ['/server/auth/token'];
service.interceptors.request.use(config => {
  config.headers.Authorization = tokenController.token;
  if (config.url && WhiteUrl.includes(config.url)) {
    console.log('white url');
  } else {
    config.headers.lang = lang.m;
  }
  return config;
});

service.interceptors.response.use(
  (response: AxiosResponse) => {
    const data = response.data;
    // 获取当前请求的接口地址
    if (data.code === 0) {
      return data.data;
    }
    if (
      data.code === ApiCode.UN_AUTH &&
      response.config.url &&
      !BlockUrl.includes(response.config.url)
    ) {
      return freshToken().then(() => service(response.config));
    } else if (
      data.code === ApiCode.FRESH_TOKEN_EXPIRE ||
      data.code === ApiCode.FRESH_TOKEN_UNKNOWN
    ) {
      // 长token已失效
      if (response.config.url && !BlockUrl.includes(response.config.url)) {
        return freshToken().then(() => service(response.config));
      } else {
        message.error(lang.v.sessionTimeout);
      }
      return Promise.reject(new Error('session timeout'));
    } else if (data.code === ApiCode.TOKEN_EXPIRE_UPDATA) {
      //如果长token失效刷新token
      tokenController.updateFreshToken(data.data);
      window.location.reload();
      return Promise.reject(new Error('should restart'));
    } else {
      if (data.code > 10000) {
        if (data.code == 20016) {
          // forceReload();
        } else {
          // 普通错误，警告提醒
          // tip.error(data.msg); todo
        }
      } else {
        // 业务严重错误，弹窗提醒
        // tip.error(data.msg); todo
      }
      message.error(data.msg || 'server error');
      return Promise.reject(new Error(data.msg || 'server error'));
    }
  },
  err => {
    if (err.config.headers.auto) {
      message.error(err.message || 'network error , please try again later');
    }
    return Promise.reject(err.response);
  }
);

let freshPromise: Promise<void> | undefined = undefined;

export function freshToken() {
  if (!navigator.onLine) {
    return Promise.reject(new Error('network error'));
  }
  const freshToken = localStorage.getItem(FRESH_TOKEN_KEY);
  if (freshToken) {
    freshPromise = post<string, { refreshToken: string }>(FRESH_TOKEN_URL, {
      refreshToken: freshToken,
    })
      .then(resp => tokenController.updateToken(resp))
      .catch(() => {
        openLogin();
        return Promise.reject(new Error('session timeout'));
      })
      .finally(() => {
        freshPromise = undefined;
      });
    return freshPromise;
  } else {
    // 跳转到登录 todo
    openLogin();
    return Promise.reject(new Error('session timeout'));
  }
}

const openLogin = () => {
  // todo
  router.push('/login');
};

export async function get<R = any, T = any>(
  url: string,
  params?: T,
  header?: Record<string, T>
): Promise<R> {
  const query = params ? qs.stringify(params) : undefined;
  const connectSymbol = url.includes('?') ? '&' : '?';
  return await service.get(
    query ? `${url}${connectSymbol}${query}` : url,
    header
  );
}

export async function post<R = any, T = any>(
  url: string,
  body?: T,
  header?: Record<string, T>
): Promise<R> {
  return await service.post(url, body, header);
}

// im
const testUrlData = [
  'https://glht.api.movtokapp.net',
  'http://47.238.47.155:8080',
];
export const baseUrl = () => {
  const Url = window.server || 'https://glht.api.movtokapp.net';
  if (testUrlData.includes(Url)) {
    return 'https://test.5wtalk.com/5wtalk/';
  } else {
    return 'https://web.5wtalk.com/5wtalk/';
  }
};

export default service;
