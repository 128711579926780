/*
 * @Name: Desert
 * @Date: 2024-07-01 15:18:42
 * @Creator: gods
 * @Description: you're the better!!
 */
// 语言包
import { reactive } from 'vue';
import en from './list/en';
// 语言包
function getLanguageCode() {
  const langString = localStorage.getItem('lang');
  if (!langString) {
    return 'en';
  }
  return langString;
}
class Lang {
  v: Record<string, string> & typeof en = en; // 设置默认语言
  m = getLanguageCode();

  change(key: string) {
    if (key === 'zh') {
      key = 'en';
    }
    this.m = key;
    localStorage.setItem('lang', key);
    import(`./list/${key}`).then(v => (this.v = v.default));
  }

  init(key = '') {
    if (!key) {
      key = localStorage.getItem('lang') || '';
    }
    if (!key) {
      const sysLang = navigator.language;
      if (sysLang.startsWith('en')) {
        key = 'en';
      } else if (sysLang === 'hi') {
        key = 'hi';
      } else if (sysLang === 'id') {
        key = 'in';
      } else if (sysLang === 'pt') {
        key = 'pt';
      } else if (sysLang === 'vi') {
        key = 'vi';
      } else if (sysLang === 'th') {
        key = 'th';
      } else {
        key = 'en';
      }
    }
    key = key || 'en';
    this.change(key);
  }

  async getPolicy() {
    let file = 'en';
    if (['en', 'vi'].includes(this.m)) {
      file = this.m;
    }
    return import(`./policy/${file}`).then(v => v.default);
  }
  async getAgreement() {
    let file = 'en';
    if (['en', 'vi'].includes(this.m)) {
      file = this.m;
    }
    return import(`./agreement/${file}`).then(v => v.default);
  }
}

export const lang = reactive(new Lang());
