import user from './user';
import allUsers from './allUsers';
import { webConfig } from './webConfig';
import payStatus from './payStatus';
import language from './language';

export default {
  webConfig,
  user,
  allUsers,
  payStatus,
  language,
};
