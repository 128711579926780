import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { syncQuery } from '@/utils';
import dataCenter from './dataCenter';
import { lang } from './lang';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

syncQuery();
lang.init(dataCenter.webConfig.query.lang);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})