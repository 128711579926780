import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import '@/assets/reset.css';
import 'vant/lib/index.css';
import 'element-plus/dist/index.css';
import Vant from 'vant';
import { initRouter } from './manager/viewManager';

initRouter(router);
createApp(App).use(router).use(Vant).use(ElementPlus).mount('#app');
