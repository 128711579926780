import { LanguageListResp } from '@/api/protocol';
import { reactive } from 'vue';

class LANGUAGELIST {
  data?: LanguageListResp[];

  constructor() {
    const storedData = localStorage.getItem('languageList');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.data = parsedData;
    }
  }

  save(data: LanguageListResp[]) {
    this.data = data;
    localStorage.setItem('allUsers', JSON.stringify(data));
  }
}

export default reactive(new LANGUAGELIST());