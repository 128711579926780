import { tokenController } from '@/manager/token';
import { get, post } from '.';
import {
  GET_CODE,
  GetCodeReq,
  LoginByEmailResp,
  LoginByEmailReq,
  LOGIN_BY_EMAIL,
  InvestmentListReq,
  InvestmentListResp,
  INVESTMENT_LIST,
  MARKET_USER,
  LOGIN_OUT,
  IM_AUTH,
  INVITE_BIND,
  InviteBindRep,
  INVITE_INFO,
  WALLET_CHANGE,
  WalletChangeReq,
  WalletChangeResp,
  WALLET_BALANCE,
  USDTBalanceResp,
  USDT_BALANCE,
  ExtractReq,
  EXTRACT,
  TIANXIE_CODE,
  BANLANCE_TRANSFER,
  BalanceTransferReq,
  USDT_INFO,
  INCOME_LIST,
  IncomeListReq,
  IncomeListResp,
  BalanceInReq,
  BANLANCE_IN,
  TALENT_CARD,
  TalentCardResp,
  TALENT_CARD_EQUITY,
  TalentCardEquityResp,
  INVESTMENT_PAGE,
  InvestmentPageResp,
  InvestmentPageReq,
  InvestmentUserReq,
  InvestmentUserResp,
  INVESTMENT_RECORDS,
  INVESTMENT_USER,
  InvestmentRecordsReq,
  InvestmentRecordsResp,
  ChildListReq,
  CHILD_LIST,
  ChildListResp,
  TANLENT_CONVERT,
  TalentConvertReq,
  TalentCardConvertReq,
  TalentCardConvertResp,
  TANLENTCARD_CONVERT,
  InviteInfoResp,
  TALENTCARD_COUPON,
  TalentCardCouponResp,
  TalentCardCouponReq,
  CAPTCHA_ADAPTIVE,
  captchaAdaptivereq,
  PAY_PLATFORM,
  PayPlatformReq,
  PayPlatformResp,
  USER_INFO,
  UserInfoResp,
  UserInfoReq,
  RefreshReq,
  Refresh,
  RefreshResp,
  TRANSACTION_COMMODITY,
  TransactionCommodityReq,
  TransactionCommodityResp,
  TRANSACTION_PUBLISH,
  TransactionPublishReq,
  TRANSACTION_RECORD,
  TransactionRecordResp,
  TRANSACTION_ORDER,
  TransactionOrderReq,
  TRANSACTION_TIME,
  TRANSACTION_NUMBER,
  TransactionNumberReq,
  NOTIFY_STATUS,
  NotifyStatusReq,
  MARKET_TASK,
  MarketTaskResp,
  MARKET_TASK_TAKE,
  MarketTaskTakeReq,
  FILE_STS,
  ADMIT_TASK,
  AdmitTaskReq,
  UserCodeListResp,
  USER_CODE_LIST,
  UserCodeListReq,
  UpdatePasswordReq,
  UPDATE_PASSWORD,
  RegisterByEmailReq,
  REGISTER_BY_EMAIL,
  UserData,
  USDT_TRANSFERINFO,
  USDT_PAYINFO,
  Login_Market,
  LoginMarketResp,
  LoginMarketReq,
  TransactionServe_goods,
  TransactionServeGoodsReq,
  TransactionServeGoodsResp,
  TransactionServe_order,
  TransactionServeOrderReq,
  RemoveOrderReq,
  FetchRemove_order,
  RemovedGoodsReq,
  Fetch_Removed_goods,
  Get_payway,
  PayWayResp,
  Transaction_servePay,
  TransactionServePayReq,
  TransactionConfirmReq,
  Transaction_confirm,
  Transaction_imGroup,
  TransactionImGroupReq,
  Transaction_order_excepion,
  TransactionOrderExcepionReq,
  Transaction_order_info,
  TransactionOrderInfoReq,
  TransactionOrderInfoResp,
  Transaction_discuss_talk,
  TransactionDiscussTalkReq,
  TransactionDiscussListResp,
  TransactionDiscussListReq,
  Transaction_discuss_list,
  TransactionOrderPageReq,
  Transaction_order_page,
  Transaction_commodity_my,
  TransactionCommodityPageReq,
  Transaction_serve_remnant_count,
  TransactionRemnantCountReq,
  Transaction_commodity_update,
  TransactionCommodityUpdateReq,
  TransactionServeConfigReq,
  Transaction_serve_config,
  LanguageListReq,
  LanguageListResp,
  LANGUAGE_LIST,
} from './protocol';
import message from '@/utils/message';

export async function fetchCode(email: string) {
  try {
    await post<null, GetCodeReq>(GET_CODE, { email });
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function fetchLogin(req: LoginByEmailReq) {
  try {
    const resp = await post<LoginByEmailResp, LoginByEmailReq>(
      LOGIN_BY_EMAIL,
      req
    );
    tokenController.update(
      resp.tokenInfo.access_token,
      resp.tokenInfo.refresh_token
    );
    return resp;
  } catch (error) {
    // nothing
  }
}
export async function fetchRegister(req: RegisterByEmailReq) {
  try {
    await post<null, RegisterByEmailReq>(REGISTER_BY_EMAIL, req);
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function fetchInvestmentList(req: InvestmentListReq) {
  try {
    return await get<InvestmentListResp, InvestmentListReq>(
      INVESTMENT_LIST,
      req
    );
  } catch (error) {
    // nothing
  }
}

export async function personal() {
  return await get<UserData>(MARKET_USER);
}

export async function logout() {
  try {
    return await post(LOGIN_OUT);
  } catch (error) {
    // nothing
  }
}

export async function fetchIMAuth() {
  try {
    return await get(IM_AUTH);
  } catch (error) {
    // nothing
  }
}

export async function invitebind(req: InviteBindRep) {
  try {
    await post<null, InviteBindRep>(INVITE_BIND, req);
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function inviteInfo() {
  try {
    return await get<InviteInfoResp, null>(INVITE_INFO);
  } catch (error) {
    // nothing
  }
}

export async function wallet(req: WalletChangeReq) {
  try {
    return await get<WalletChangeResp, WalletChangeReq>(WALLET_CHANGE, req);
  } catch (error) {
    // nothing
  }
}

export async function fetchWithdrawalFields() {
  try {
    return await get<WalletChangeResp>(WALLET_BALANCE);
  } catch (error) {
    // nothing
  }
}

export async function fetchUSDTFields() {
  try {
    return await get<USDTBalanceResp>(USDT_BALANCE);
  } catch (error) {
    // nothing
  }
}

export async function extract(req: ExtractReq) {
  try {
    await post<null, ExtractReq>(EXTRACT, req);
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function UstdInfo() {
  try {
    return await get(USDT_INFO);
  } catch (error) {
    // nothing
  }
}

export async function UstdPayInfo() {
  try {
    return await get(USDT_PAYINFO);
  } catch (error) {
    // nothing
  }
}

export async function UstdTransferInfo() {
  try {
    return await get(USDT_TRANSFERINFO);
  } catch (error) {
    // nothing
  }
}
export async function tixian_code() {
  try {
    return await post(TIANXIE_CODE);
  } catch (error) {
    // nothing
  }
}

export async function transferApi(req: BalanceTransferReq) {
  try {
    await post<null, BalanceTransferReq>(BANLANCE_TRANSFER, req);
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function income(req: IncomeListReq) {
  try {
    return await get<IncomeListResp, IncomeListReq>(INCOME_LIST, req);
  } catch (error) {
    // nothing
  }
}

export async function in_wallet(req: BalanceInReq) {
  await post<null, BalanceInReq>(BANLANCE_IN, req);
  return 'success';
}

export async function card_pay() {
  try {
    return await get<TalentCardResp, null>(TALENT_CARD);
  } catch (error) {
    // nothing
  }
}

export async function card_info() {
  try {
    return await get<TalentCardEquityResp, null>(TALENT_CARD_EQUITY);
  } catch (error) {
    // nothing
  }
}

export async function invest(req: InvestmentPageReq) {
  try {
    return await get<InvestmentPageResp, InvestmentPageReq>(
      INVESTMENT_PAGE,
      req
    );
  } catch (error) {
    // nothing
  }
}

export async function invest_myjoin(req: InvestmentUserReq) {
  try {
    return await get<InvestmentUserResp, InvestmentUserReq>(
      INVESTMENT_USER,
      req
    );
  } catch (error) {
    // nothing
  }
}

export async function shouyi(req: InvestmentRecordsReq) {
  try {
    return await get<InvestmentRecordsResp, InvestmentRecordsReq>(
      INVESTMENT_RECORDS,
      req
    );
  } catch (error) {
    // nothing
  }
}

let payTime = 0;
export const charge = async (params: any) => {
  if (Date.now() - payTime < 3000) {
    return;
  }
  payTime = Date.now();

  try {
    const result = await post('/server/market/talent/card/buy', params);
    // message.success('success');
    return result;
  } finally {
    payTime = 0; // 确保不论成功或失败，payTime都会重置
  }
};

//community
export const getCommunityList = async (req: ChildListReq) => {
  try {
    return await get<ChildListResp, ChildListReq>(CHILD_LIST, req);
  } catch (error) {
    // nothing
  }
};

//redemptionCode
//community
export const convert_use = async (req: TalentConvertReq) => {
  return await get<null, TalentConvertReq>(TANLENT_CONVERT, req);
};
//community
export const getByCode = async (req: TalentCardConvertReq) => {
  try {
    return await get<TalentCardConvertResp, TalentCardConvertReq>(
      TANLENTCARD_CONVERT,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const verificationCoupon = async (req: TalentCardCouponReq) => {
  try {
    return await get<TalentCardCouponResp, TalentCardCouponReq>(
      TALENTCARD_COUPON,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const checkCodeRequest_wallet_pay = async (req: captchaAdaptivereq) => {
  try {
    return await post<null, captchaAdaptivereq>(CAPTCHA_ADAPTIVE, req);
  } catch (error) {
    // nothing
  }
};

export const platform = async (req: PayPlatformReq) => {
  try {
    return await get<PayPlatformResp[], PayPlatformReq>(PAY_PLATFORM, req);
  } catch (error) {
    // nothing
  }
};

export const getUserInfo = async (req: UserInfoReq) => {
  try {
    return await get<UserInfoResp, UserInfoReq>(USER_INFO, req);
  } catch (error) {
    // nothing
  }
};

export const refresh = async (req: RefreshReq) => {
  try {
    return await get<RefreshResp, RefreshReq>(Refresh, req);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServeList = async (
  req: TransactionCommodityReq
) => {
  try {
    return await get<TransactionCommodityResp, TransactionCommodityReq>(
      TRANSACTION_COMMODITY,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServeNumber = async (
  req: TransactionNumberReq
) => {
  try {
    return await get<null, TransactionNumberReq>(TRANSACTION_NUMBER, req);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServePayment = async () => {
  try {
    return await get<TransactionRecordResp, null>(TRANSACTION_RECORD);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServePendingOrder = async (
  req: TransactionOrderReq
) => {
  try {
    return await get<null, TransactionOrderReq>(TRANSACTION_ORDER, req);
  } catch (error) {
    // nothing
  }
};

/** 获取商品详细信息-通过id查询*/
export const fetchTransactionServeGoods = async (
  req: TransactionServeGoodsReq
) => {
  try {
    return await get<TransactionServeGoodsResp, TransactionServeGoodsReq>(
      TransactionServe_goods,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServeOrder = async (
  req: TransactionServeOrderReq
) => {
  try {
    return await post<null, TransactionServeOrderReq>(
      TransactionServe_order,
      req
    );
  } catch (error) {
    // nothing
  }
};
export const fetchTransactionServeTime = async () => {
  try {
    return await get<null, null>(TRANSACTION_TIME);
  } catch (error) {
    // nothing
  }
};

export const fetchRemoveOrder = async (req: RemoveOrderReq) => {
  try {
    return await get<null, RemoveOrderReq>(FetchRemove_order, req);
  } catch (error) {
    // nothing
  }
};

export const fetchRemovedGoods = async (req: RemovedGoodsReq) => {
  return await get<null, RemovedGoodsReq>(Fetch_Removed_goods, req);
};

export const fetchTransactionServePay = async (req: TransactionServePayReq) => {
  try {
    return await get<null, TransactionServePayReq>(Transaction_servePay, req);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServeConfirm = async (
  req: TransactionConfirmReq
) => {
  try {
    return await get<null, TransactionConfirmReq>(Transaction_confirm, req);
  } catch (error) {
    // nothing
  }
};

export const fetchIMChat = async (req: TransactionImGroupReq) => {
  try {
    return await get<null, TransactionImGroupReq>(Transaction_imGroup, req);
  } catch (error) {
    // nothing
  }
};

export const fetchErrOrder = async (req: TransactionOrderExcepionReq) => {
  try {
    return await get<null, TransactionOrderExcepionReq>(
      Transaction_order_excepion,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchOrderInfo = async (req: TransactionOrderInfoReq) => {
  try {
    return await get<TransactionOrderInfoResp, TransactionOrderInfoReq>(
      Transaction_order_info,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionOrderChat = async (
  req: TransactionDiscussTalkReq
) => {
  try {
    return await post<null, TransactionDiscussTalkReq>(
      Transaction_discuss_talk,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionOrderChatList = async (
  req: TransactionDiscussListReq
) => {
  try {
    return await get<TransactionDiscussListResp[], TransactionDiscussListReq>(
      Transaction_discuss_list,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const status = async (req: NotifyStatusReq) => {
  try {
    return await get<null, NotifyStatusReq>(NOTIFY_STATUS, req);
  } catch (error) {
    // nothing
  }
};

export const task = async () => {
  try {
    return await get<MarketTaskResp, null>(MARKET_TASK);
  } catch (error) {
    // nothing
  }
};

export const act_task = async (req: MarketTaskTakeReq) => {
  try {
    return await post<null, MarketTaskTakeReq>(MARKET_TASK_TAKE, req);
  } catch (error) {
    // nothing
  }
};

export const file_sts = async () => {
  try {
    return await get<null, null>(FILE_STS);
  } catch (error) {
    // nothing
  }
};

export const admit_tasks = async (req: AdmitTaskReq) => {
  try {
    return await post<null, AdmitTaskReq>(ADMIT_TASK, req);
  } catch (error) {
    // nothing
  }
};

export const code_num = async (req: UserCodeListReq) => {
  try {
    return await get<UserCodeListResp, UserCodeListReq>(USER_CODE_LIST, req);
  } catch (error) {
    // nothing
  }
};

export const fetchSetPassword = async (req: UpdatePasswordReq) => {
  try {
    return await post<null, UpdatePasswordReq>(UPDATE_PASSWORD, req);
  } catch (error) {
    // nothing
  }
};

export const market = async (req: LoginMarketReq) => {
  try {
    const resp = await get<LoginMarketResp, LoginMarketReq>(Login_Market, req);
    tokenController.update(
      resp.tokenInfo.access_token,
      resp.tokenInfo.refresh_token
    );
    return resp;
  } catch (error) {
    // nothing
  }
};

export const Getpayway = async () => {
  try {
    return await get<PayWayResp, null>(Get_payway, null);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionMyOverOrder = async (
  req: TransactionOrderPageReq
) => {
  try {
    return await get<any, TransactionOrderPageReq>(Transaction_order_page, req);
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionMyPendingOrder = async (
  req: TransactionCommodityPageReq
) => {
  try {
    return await get<any, TransactionCommodityPageReq>(
      Transaction_commodity_my,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServeRemnantCount = async (
  req: TransactionRemnantCountReq
) => {
  try {
    return await get<null, TransactionRemnantCountReq>(
      Transaction_serve_remnant_count,
      req
    );
  } catch (error) {
    // nothing
  }
};

export const fetchTransactionServePublish = async (
  req: TransactionPublishReq
) => {
  return await post<null, TransactionPublishReq>(TRANSACTION_PUBLISH, req);
};

export const updataPeddingOrder = async (
  req: TransactionCommodityUpdateReq
) => {
  return await post<null, TransactionCommodityUpdateReq>(
    Transaction_commodity_update,
    req
  );
};

export const fetchTransactionServeConfig = async (
  req: TransactionServeConfigReq
) => {
  return await get<string, TransactionServeConfigReq>(
    Transaction_serve_config,
    req
  );
};

export const fetchLanguageList = async (req: LanguageListReq) => {
  try {
    return await get<LanguageListResp[], LanguageListReq>(LANGUAGE_LIST, req);
  } catch (error) {
    // nothing
  }
};
