import { ElMessage } from 'element-plus';

let messageQueue: ReturnType<typeof ElMessage>[] = [];
let displayedMessages = 0;
const maxMessages = 3;

const message = {
  error: (msg: string) => {
    showMessage(msg, 'error');
  },
  success: (msg: string) => {
    showMessage(msg, 'success');
  },
  warning: (msg: string) => {
    showMessage(msg, 'warning');
  },
};

const showMessage = (msg: string, type: 'error' | 'success' | 'warning') => {
  // Type narrowed here
  if (displayedMessages >= maxMessages) {
    const oldestMessage = messageQueue.shift();
    if (oldestMessage) {
      oldestMessage.close();
    }
  }

  const newMessage = ElMessage({
    message: msg,
    type: type,
    grouping: true,
    onClose: () => {
      displayedMessages--;
      messageQueue = messageQueue.filter(m => m !== newMessage);
    },
  });

  messageQueue.push(newMessage);
  displayedMessages++;
};

export default message;
