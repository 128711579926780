import { UserData } from '@/api/protocol';
import { reactive } from 'vue';

class AllUser {
  data?: UserData[];

  constructor() {
    const storedData = localStorage.getItem('allUsers');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.data = parsedData;
    }
  }

  save(data: UserData[]) {
    this.data = data;
    localStorage.setItem('allUsers', JSON.stringify(data));
  }
  delecte(id: string) {
    this.data = this.data?.filter(item => item.userId !== Number(id));
    localStorage.setItem('allUsers', JSON.stringify(this.data));
  }
}

export default reactive(new AllUser());
