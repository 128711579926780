export default class CountDown {
  frameTime = 0;
  timer = 0;
  value = 0;
  callback?: (value: number) => void;
  constructor(frameTime = 1000) {
    this.frameTime = frameTime;
  }
  start(value: number, callback: (value: number) => void) {
    this.value = value;
    this.callback = callback;
    this.timer = setInterval(this.update, this.frameTime);
    this.callback(this.value);
  }
  update = () => {
    this.value--;
    this.callback?.(this.value);
    if (this.value < 1) {
      this.clearTimer();
      this.callback = undefined;
    }
  };
  private clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = 0;
    }
  }
  clear() {
    this.clearTimer();
    this.callback = undefined;
  }
}
