// 普通token
export const TOKEN_KEY = 'access_token';
// 刷新token
export const FRESH_TOKEN_KEY = 'refresh_Token';

export enum ApiCode {
  FRESH_TOKEN_UNKNOWN = 400, // 刷新token不存在
  FRESH_TOKEN_EXPIRE = 401, // 刷新token已失效
  UN_AUTH = 403, // 未登录或者登录失效
  TOKEN_EXPIRE_UPDATA = 406, //刷新长token
}
